import axios from '@/configs/http/internal-api-axios'
import get from 'lodash-es/get'
import { extendMasterRead } from '@/utils/function-utils'

const baseUrl = '/api/v1/user'

async function getItems(
  perPage = 10,
  currentPage = 1,
  keyword = '',
  departmentId = '',
  saasFilter = '',
  isMaster = false
) {
  let url = `${baseUrl}?perPage=${perPage}&currentPage=${currentPage}&keyword=${keyword}&department_id=${departmentId}&saas_fillter=${saasFilter}`
  url = extendMasterRead(isMaster, url)
  const response = await axios.get(url).then((res) => {
    return res.data
  })
  return {
    items: response.data.items,
    total: response.data.total,
  }
}

async function getItemsById(employeeId, isMaster = false) {
  let url = `${baseUrl}/${employeeId}`
  url = extendMasterRead(isMaster, url)
  const response = await axios.get(url).then((res) => {
    return res.data
  })
  return response.data
}

function getFields() {
  return [
    { key: 'user_code', label: '従業員ID', sortable: true },
    { key: 'avatar', label: 'ロゴ', sortable: true },
    { key: 'name', label: '従業員名', sortable: true },
    { key: 'email', label: 'メールアドレス', sortable: true },
    { key: 'department_name', label: '部署', sortable: true },
    { key: 'position_name', label: '役職', sortable: true },
    { key: 'use_app', label: '利用中のサービス数', sortable: true },
    { key: 'status', label: 'ステータス', sortable: true },
    { key: 'actions', label: '', sortable: false },
  ]
}

async function getDepartments() {
  const url = 'api/v1/company/department-list'
  const response = await axios.get(url).then((res) => {
    return get(res, 'data.data.items', [])
  })
  return response.map((res) => {
    return { value: res.id, text: res.name }
  })
}

async function getPositions() {
  const url = 'api/v1/company/position'
  const response = await axios.get(url).then((res) => {
    return get(res, 'data.data.items', [])
  })
  return response.map((res) => {
    return { value: res.id, text: res.name }
  })
}

async function createItem(formData) {
  const response = await axios
    .post(baseUrl, formData)
    .then((response) => {
      return {
        success: true,
        data: response.data,
      }
    })
    .catch((error) => {
      return {
        success: false,
        data: error.response.data.message,
      }
    })
  return {
    success: response.success,
    data: response.data,
  }
}

async function editItem(formData) {
  const url = `${baseUrl}/${formData.id}`
  const response = await axios
    .patch(url, formData)
    .then((res) => {
      return {
        success: true,
        data: res.data,
      }
    })
    .catch((err) => {
      return {
        success: false,
        data: err.response.data,
      }
    })
  return {
    success: response.success,
    data: response.data,
  }
}

async function deleteItem(employeeId) {
  if (employeeId !== '') {
    const url = `${baseUrl}/${employeeId}`
    return axios.delete(url).then((res) => {
      return res.data.success
    })
  }
  return false
}

async function getServicesByEmployeeId(employeeId, perPage = 1, currentPage = 10) {
  const url = `${baseUrl}/${employeeId}/sass?perPage=${perPage}&currentPage=${currentPage}`
  const response = await axios.get(url).then((res) => {
    return res.data
  })
  return {
    items: response.data.items,
    total: response.data.total,
  }
}

function getServiceFields() {
  return [
    { key: 'app_logo', label: 'ロゴ', sortable: false },
    { key: 'app_name', label: 'サービス名', sortable: false },
    { key: 'created_at', label: '最初連携', sortable: false },
  ]
}
export const employeeManagementService = {
  getFields,
  getItems,
  getDepartments,
  getPositions,
  getServicesByEmployeeId,
  getServiceFields,
  createItem,
  editItem,
  deleteItem,
  getItemsById,
}

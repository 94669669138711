<template>
  <div class="EMForm">
    <b-form>
      <b-form-group id="input-group-1" class="EMForm__image">
        <b-input-group>
          <img :src="form.avatar || defaultAvatar" alt="avatar-image" @click="handleClickImage" />
          <label :ref="imageRef" for="input-image" class="EMForm__avatar-label">
            <b-button class="EMForm__avatar-button" variant="light">画像アップロード</b-button>
          </label>
          <input
            id="input-image"
            ref="upload-image"
            class="EMForm__avatar-file"
            type="file"
            accept=".jpg,.jpeg,.png"
            @change="loadFile"
          />
          <span v-if="!!errorMessages.form.avatar" class="label-error"
            >{{ errorMessages.form.avatar }}
          </span>
        </b-input-group>
      </b-form-group>

      <b-form-group id="input-group-1" label="従業員ID" label-for="input-1" class="required">
        <b-input-group>
          <b-form-input
            id="input-1"
            :value="form['user_code']"
            type="text"
            :class="status($v.form['user_code'])"
            :formatter="formatter"
            @change="setFormValue('user_code', $event)"
          />
          <b-input-group-append v-if="!!errorMessages.form['user_code']" class="icon-alert">
            <img src="@/assets/icons/ic_alert.svg" alt="alert-icon" />
          </b-input-group-append>
        </b-input-group>
        <span v-if="!!errorMessages.form['user_code']" class="label-error"
          >{{ errorMessages.form['user_code'] }}
        </span>
      </b-form-group>

      <b-form-group id="input-group-2" label="従業員名" label-for="input-2" class="required">
        <b-input-group>
          <b-form-input
            id="input-2"
            :value="form.name"
            type="text"
            :class="status($v.form.name)"
            :formatter="formatter"
            @change="setFormValue('name', $event)"
          />
          <b-input-group-append v-if="!!errorMessages.form.name" class="icon-alert">
            <img src="@/assets/icons/ic_alert.svg" alt="alert-icon" />
          </b-input-group-append>
        </b-input-group>
        <span v-if="!!errorMessages.form.name" class="label-error"
          >{{ errorMessages.form.name }}
        </span>
      </b-form-group>

      <b-form-group id="input-group-3" label="メールアドレス" label-for="input-3" class="required">
        <b-input-group>
          <b-form-input
            id="input-3"
            :value="form.email"
            type="text"
            :class="status($v.form.email)"
            :formatter="formatter"
            @change="setFormValue('email', $event)"
          />
          <b-input-group-append v-if="!!errorMessages.form.email" class="icon-alert">
            <img src="@/assets/icons/ic_alert.svg" alt="alert-icon" />
          </b-input-group-append>
        </b-input-group>
        <span v-if="!!errorMessages.form.email" class="label-error"
          >{{ errorMessages.form.email }}
        </span>
      </b-form-group>

      <b-form-group id="select-group" label="部署" label-for="select" class="required">
        <b-form-select
          id="select"
          :value="form.department_id"
          class="form-select"
          size="sm"
          :options="departmentList"
          :class="status($v.form.department_id)"
          @change="setFormValue('department_id', $event)"
        />
        <span v-if="!!errorMessages.form.department_id" class="label-error"
          >{{ errorMessages.form.department_id }}
        </span>
      </b-form-group>

      <b-form-group id="select-group-2" label="役職" label-for="select-2" class="required">
        <b-form-select
          id="select-2"
          :value="form.position_id"
          class="form-select"
          size="sm"
          :options="positionList"
          :class="status($v.form.position_id)"
          @change="setFormValue('position_id', $event)"
        />
        <span v-if="!!errorMessages.form.position_id" class="label-error"
          >{{ errorMessages.form.position_id }}
        </span>
      </b-form-group>

      <b-form-group id="select-group-3" label="ステータス" label-for="select-3" class="required">
        <b-form-select
          id="select-3"
          v-model="form.status"
          class="form-select"
          size="sm"
          :options="statusList"
          :class="status($v.form.status)"
          @change="setFormValue('status', $event)"
        />
        <span v-if="!!errorMessages.form.status" class="label-error"
          >{{ errorMessages.form.status }}
        </span>
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
import { employeeManagementService } from '@/services/employee-management-service'
import { mapGetters } from 'vuex'
import { DEFAULT_IMAGE, MAX_FILE_SIZE, MAX_LENGTH } from '@/utils/constants'
import { MESSAGES } from '@/utils/messages'
import { email, required } from 'vuelidate/lib/validators'
import {
  fileImageToBase64,
  notFreeMail,
  validateFileSize,
  validateFileType,
} from '@/utils/function-utils'

export default {
  name: 'EMForm',
  props: {
    formData: {
      type: Object,
      default: null,
    },
    triggerValidate: {
      type: Boolean,
      default: false,
    },
    isValid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {},
      errorMessages: {
        form: {
          user_code: '',
          name: '',
          email: '',
          department_id: '',
          position_id: '',
          status: '',
          avatar: '',
        },
      },
      departmentList: [],
      positionList: [],
      statusList: [],
      imageRef: 'image-label',
      defaultAvatar: DEFAULT_IMAGE,
      allowFileTypes: ['png', 'jpg', 'jpeg'],
    }
  },
  validations: {
    form: {
      user_code: {
        required,
      },
      name: {
        required,
      },
      email: {
        required,
        email,
      },
      department_id: {
        required,
      },
      position_id: {
        required,
      },
      status: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters('commonSettings', ['user_list_status']),
    defaultFormData() {
      return {
        user_code: '',
        name: '',
        email: '',
        department_id: '',
        position_id: '',
        status: '',
        avatar: '',
      }
    },
  },
  watch: {
    form(newValue) {
      this.$emit('update:formData', newValue)
    },
    triggerValidate() {
      this.$emit('update:isValid', this.isValidForm())
    },
  },
  async created() {
    this.positionList = await employeeManagementService.getPositions()
    this.departmentList = await employeeManagementService.getDepartments()
  },
  mounted() {
    this.form = { ...this.defaultFormData }
    if (this.formData) {
      this.form = { ...this.form, ...this.formData }
    }
    this.statusList = this.user_list_status.map((item) => ({ value: item.id, text: item.name }))
  },
  methods: {
    loadFile(event) {
      const file = event.target.files[0]
      this.$refs['upload-image'].value = ''
      this.errorMessages.form.avatar = ''
      // validate file type
      if (!validateFileSize(file, MAX_FILE_SIZE.IMAGE)) {
        this.errorMessages.form.avatar = MESSAGES.COMMON.MSG99
        return
      }
      // validate file size
      if (!validateFileType(file, this.allowFileTypes)) {
        this.errorMessages.form.avatar = MESSAGES.COMMON.MSG23
        return
      }
      fileImageToBase64(file).then((result) => {
        this.form.avatar = result
      })
    },
    handleClickImage() {
      this.$refs[this.imageRef].click()
    },
    status(validation) {
      return {
        invalid: validation.$dirty && validation.$invalid,
      }
    },
    setValidateErrorMessage(instance, name) {
      // check required
      if (!instance.required) {
        this.errorMessages.form[name] = MESSAGES.COMMON.MSG08
        return
      } else {
        this.errorMessages.form[name] = ''
      }
      // check email
      this.checkEmail(instance, name)
    },
    isValidForm() {
      this.$v.form.$touch()
      const isValid = !this.$v.form.$invalid
      this.errorMessages.form.avatar = ''
      if (!isValid) {
        Object.keys(this.errorMessages.form).forEach((key) => {
          if (key !== 'avatar') {
            this.setValidateErrorMessage(this.$v.form[key], key)
          }
        })
      }
      return isValid
    },
    setFormValue(name, value) {
      if (value === undefined) {
        return
      }
      this.form[name] = value
      const validateInstance = this.$v.form[name]
      validateInstance.$touch()
      this.setValidateErrorMessage(validateInstance, name)
    },
    checkEmail(instance, name) {
      if (name === 'email') {
        if (!instance.email) {
          this.errorMessages.form[name] = MESSAGES.COMMON.MSG09
        }
      } else {
        if (!this.errorMessages.form[name]) {
          this.errorMessages.form[name] = ''
        }
      }
    },
    formatter(e) {
      return String(e).substring(0, MAX_LENGTH.DEFAULT)
    },
  },
}
</script>
